@import "./base/base";
@import "./base/colors";
@import "./components/namePage.scss";
@import "./components/app.scss";
@import "./components/skills";
@import "./components/tech";
@import "./components/tech2";
@import "./components/tech3";
@import "./components/tech4";
@import "./components/info";
@import "./base/buttons";
@import "./components/projects2";
@import "./components/other";
@import "./components/exit";
@import "./base/custom";
@import "./components/project";
