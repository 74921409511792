//card 4

.tech-card4 {
  border-radius: 8px;
  transition: box-shadow 0.5s;
  will-change: transform;
  // box-shadow: 5px 5px 10px grey;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-rows: [techPic4] 0.75fr [techSkills4] 3fr;
  grid-template-columns: [techCardBody4] auto [end];
  // grid-row: techBody;
  // grid-column: techc4/techRightMarg;
}
.tech-card4:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

.tech-card4-pic4 {
  border-radius: 8px 8px 0 0;
  grid-row: techPic4;
  grid-column: techCardBody4;
  background: #7a7a7a;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 18px;

    color: $mywhite;

    height: 1.5rem;
    @media (min-width: 1920px) {
      font-size: 27px;
    }
  }
}

//list

.tech-card4-list4 {
  border-radius: 0 0 8px 8px;
  background: lighten(#d8d0da, 0);
  grid-row: techSkills4;
  grid-column: techCardBody4;
  display: grid;
  grid-template-rows: [list1-topMarg] 2rem [list1-1] 2.5fr [list1-2] 2.5fr [list1-3] 2.75fr [list1-4] 2.5fr [list1-bottomMarg] 2rem;
  grid-template-columns: [listIcon] 4fr [list1Body] 8fr [end];
}

//first row

.list4-icon1 {
  background: $thelightestpurp;
  grid-row: list1-1;
  grid-column: listIcon/list1Body;
  background: url("/images/Heroku.png") no-repeat 0 0px;
  background-size: cover;
  margin: 5px 21px;
}
.list4R1 {
  grid-row: list1-1;
  grid-column: list1Body;
  display: flex;
  justify-content: left;
  align-items: center;

  h2 {
    font-size: 15px;
    margin-left: 1rem;
    width: 10rem;
    color: rgb(89, 89, 89);
  }
}
//second row

.list4-icon2 {
  background: $thelightestpurp;
  grid-row: list1-2;
  grid-column: listIcon/list1Body;
  background: url("/images/netlify.png");
  background-size: cover;
  margin: 8px 24px;
}
.list4R2 {
  grid-row: list1-2;
  grid-column: list1Body;
  display: flex;
  justify-content: left;
  align-items: center;

  h2 {
    font-size: 15px;
    margin-left: 1rem;
    width: 10rem;
    color: rgb(89, 89, 89);
  }
}

//third row
