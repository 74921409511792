.proj__container {
  background: lighten($color: #f8f8f6, $amount: 2);
}
.proj-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  // padding: 0 50rem 0 10rem;
  @media (min-width: 320px) {
    padding: 2.5rem 2.5rem 0 2.5rem;
    height: 25rem;
  }
  @media (min-width: 400px) {
    padding: 2.5rem 2rem 0 2rem;
    height: 25rem;
  }

  @media (min-width: 768px) {
    padding: 12rem 0 0 0px;
    height: 42rem;
  }
  @media (min-width: 1024px) {
    padding: 9rem 0 0 0px;
    height: 42rem;
  }

  @media (min-width: 1280px) {
    padding: 8rem 2rem 0 0rem;
    height: 50rem;
  }
  @media (min-width: 1440px) {
    padding: 7rem 0rem 0 0rem;
    height: 45rem;
  }
  @media (min-width: 1600px) {
    padding: 8rem 0rem 0 0rem;
    height: 60rem;
  }

  h1 {
    font-family: "boldest", Helvetica, sans-serif;

    padding-top: 10rem;
    @media (min-width: 320px) {
      font-size: 23px;
      padding-top: 2rem;
    }
    @media (min-width: 360px) {
      font-size: 23px;
      padding-top: 3rem;
    }

    @media (min-width: 768px) {
      line-height: 43px;
      letter-spacing: -1px;
      font-size: 40px;
      padding-top: 2rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 1024px) {
      line-height: 50px;
      font-size: 50px;
      padding-top: 4rem;
    }
    @media (min-width: 1280px) {
      line-height: 65px;
      letter-spacing: -1px;
      padding-top: 6rem;
      font-size: 60px;
      margin-bottom: 3rem;
    }
    @media (min-width: 1600px) {
      font-size: 60px;
      padding-top: 6rem;
    }
  }
  p {
    font-family: "light", Helvetica, sans-serif;

    padding-right: 10rem;
    @media (min-width: 320px) {
      font-size: 15px;
      padding-right: 0;
    }

    @media (min-width: 768px) {
      line-height: 32px;
      letter-spacing: 0px;
      font-size: 20px;
      padding-right: 0rem;
    }
    @media (min-width: 1024px) {
      font-size: 24px;
      padding-right: 0;
      letter-spacing: 0px;
      line-height: 37px;
    }
    @media (min-width: 1280px) {
      font-size: 25px;
      letter-spacing: -1.5px;
      line-height: 37px;
      padding-right: 0;
    }
    @media (min-width: 1600px) {
      font-size: 40px;
      letter-spacing: -1.5px;
      line-height: 52px;
      padding-right: 0;
    }
  }
}
