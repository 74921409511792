.tech-container {
  height: 40rem;
  width: 100vw;
  background: lighten(#f3f3f1, 2);

  display: grid;
  grid-template-rows: [techTopMarg] 0 [techBody] 8fr [techBottomMarg] 1fr [end];
  grid-template-columns: [techLeftMarg] 10% [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 10% [end];

  @media (min-width: 320px) {
    height: 150rem;
    grid-template-rows: [techTopMarg] 20rem [techBody] 120rem [techBottomMarg] 10rem [end];
    grid-template-columns: [techLeftMarg] 5% [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 5% [end];
  }

  @media (min-width: 360px) {
    height: 150rem;
    grid-template-rows: [techTopMarg] 20rem [techBody] 120rem [techBottomMarg] 10rem [end];
    grid-template-columns: [techLeftMarg] 5% [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 5% [end];
  }

  @media (min-width: 768px) {
    height: 125rem;
    grid-template-rows: [techTopMarg] 35rem [techBody] 80rem [techBottomMarg] 10rem [end];
    grid-template-columns: [techLeftMarg] 5% [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 5% [end];
  }

  @media (min-width: 1024px) {
    height: 130rem;
    grid-template-rows: [techTopMarg] 30% [techBody] 65% [techBottomMarg] 5% [end];
    grid-template-columns: [techLeftMarg] 8.5rem [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 8.5rem [end];
  }

  @media (min-width: 1280px) {
    height: 160rem;
    grid-template-rows: [techTopMarg] 40rem [techBody] 100rem [techBottomMarg] 10rem [end];
    grid-template-columns: [techLeftMarg] 0 [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 0 [end];
  }

  @media (min-width: 1400px) {
    height: 100rem;
    grid-template-rows: [techTopMarg] 45rem [techBody] 38rem [techBottomMarg] 33rem [end];
    grid-template-columns: [techLeftMarg] 8.5rem [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 8.5rem [end];
  }
  @media (min-width: 1600px) {
    height: 125rem;
    grid-template-rows: [techTopMarg] 55rem [techBody] 50rem [techBottomMarg] 33rem [end];
    grid-template-columns: [techLeftMarg] 8.5rem [techc1] 2.5fr [techc2] 2.5fr [techc3] 2.5fr [techc4] 2.5fr [techRightMarg] 8.5rem [end];
  }
}

.tech-interior-container {
  grid-row: techBody;
  grid-column: techc1/techRightMarg;

  display: grid;
  font-family: "medium";

  @media (min-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 3rem;

    grid-column: techLeftMarg/end;
    margin: 0 0%;
    padding: 0 5rem;
  }
  @media (min-width: 360px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 3rem;

    grid-column: techLeftMarg/end;
    margin: 0 0%;
    padding: 0 7rem;
  }

  @media (min-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 4rem;

    grid-column: techLeftMarg/end;
    padding: 0 9rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 2fr));
    gap: 8rem;
  }
  @media (min-width: 1020px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 2fr));
    gap: 8rem;
    padding: 0 5rem;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    gap: 12rem;
    padding: 0rem 25rem;
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(auto-fit, minmax(2px, 1fr));
    gap: 3rem;
    padding: 0rem;
    margin: 0 10%;
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 5rem;
  }
}

//card 1

.tech-card1 {
  border-radius: 8px;
  transition: box-shadow 0.5s;
  will-change: transform;
  // box-shadow: 5px 5px 10px grey;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-rows: [techPic1] 0.75fr [techSkills1] 3fr;
  grid-template-columns: [techCardBody1] auto [end];

  // grid-row: techBody;
  // grid-column: techc1;
}
.tech-card1:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

.tech-card1-pic1 {
  border-radius: 8px 8px 0 0;
  grid-row: techPic1;
  grid-column: techCardBody1;
  background: #7a7a7a;

  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    //
    color: $mywhite;

    height: 1.5rem;
    font-size: 18px;
  }
}
.tech-card1-list1 {
  border-radius: 0px 0px 8px 8px;
  margin-top: 0;

  background: lighten(#d8d0da, 0);
  grid-row: techSkills1;
  grid-column: techCardBody1;
  display: grid;
  grid-template-rows: [list1-topMarg] 2rem [list1-1] 2.5fr [list1-2] 2.5fr [list1-3] 2.5fr [list1-4] 2.5fr [list1-bottomMarg] 2rem;
  grid-template-columns: [listIcon] 4fr [list1Body] 8fr [end];
}

// first row
.list1-icon1 {
  // background: $thelightestpurp;
  grid-row: list1-1;
  grid-column: listIcon/list1Body;
  background: url("/images/javascriptIcon.png") no-repeat;
  background-size: cover;
  margin: 6px 19px;
}
.list1R1 {
  grid-row: list1-1;
  grid-column: list1Body;
  display: flex;
  justify-content: left;
  align-items: center;

  h2 {
    font-size: 15px;
    margin-left: 1rem;
    width: 10rem;
    color: rgb(89, 89, 89);
  }
}
//second row

.list1-icon2 {
  // background: $thelightestpurp;
  grid-row: list1-2;
  grid-column: listIcon/list1Body;
  background: url("/images/html.png");
  background-size: cover;
  margin: 5px 20px;
}
.list1R2 {
  grid-row: list1-2;
  grid-column: list1Body;
  display: flex;
  justify-content: left;
  align-items: center;

  h2 {
    font-size: 15px;
    margin-left: 1rem;
    width: 10rem;
    color: rgb(89, 89, 89);
  }
}

//third row

.list1-icon3 {
  // background: $thelightestpurp;
  grid-row: list1-3;
  grid-column: listIcon/list1Body;
  background: url("/images/reactother.png");
  background-size: cover;
  background-position: -3rem 0;
  margin: 2px 19px;
}
.list1R3 {
  grid-row: list1-3;
  grid-column: list1Body;
  display: flex;
  justify-content: left;
  align-items: center;

  h2 {
    font-size: 15px;
    margin-left: 1rem;
    width: 10rem;
    color: rgb(89, 89, 89);
  }
}

//fourth row

.list1-icon4 {
  // background: $thelightestpurp;
  grid-row: list1-4;
  grid-column: listIcon/list1Body;
  background: url("/images/css.png");
  background-size: cover;
  background-position: 0;
  margin: 0px 12px;
}
.list1R4 {
  grid-row: list1-4;
  grid-column: list1Body;
  display: flex;
  justify-content: left;
  align-items: center;

  h2 {
    font-size: 15px;
    margin-left: 1rem;
    width: 10rem;
    color: rgb(89, 89, 89);
  }
}

//card 3

.tech-skills {
  grid-column: techc1/techc4;
  grid-row: techTopMarg;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 320px) {
    grid-column: techLeftMarg/end;
    grid-row: techTopMarg;
    padding-left: 2rem;
    padding-right: 0rem;
  }
  @media (min-width: 400px) {
    padding-left: 2.5rem;
    // padding-right: 5rem;
  }

  @media (min-width: 768px) {
    grid-column: techLeftMarg/end;
    grid-row: techTopMarg;

    padding-right: 0rem;
    align-items: left;
    padding-left: 1.5rem;
  }

  @media (min-width: 1440px) {
    justify-content: center;
    align-items: flex-start;
    padding-left: 15rem;
  }

  @media (min-width: 1600px) {
    justify-content: center;
    align-items: flex-start;
    padding-left: 18rem;
  }

  h1 {
    display: inline-block;
    padding: 0;
    margin: 0;
    color: black;

    letter-spacing: -1px;
    font-family: "bold", Helvetica, sans-serif;
    @media (min-width: 320px) {
      font-size: 23px;
      padding: 0 1rem 0 0rem;
      text-align: left;
    }
    @media (min-width: 360px) {
      font-size: 23px;
      padding: 0 2rem 0 0rem;
      text-align: left;
    }

    @media (min-width: 768px) {
      line-height: 35px;
      letter-spacing: -1px;
      font-size: 24px;
      padding: 4rem 0 0 3rem;
    }

    @media (min-width: 1024px) {
      line-height: 40px;
      font-size: 35px; 
      padding: 0rem 6rem 0 3.5rem;

      letter-spacing: 0px;
    }

    @media (min-width: 1280px) {
      font-size: 40px;
      padding: 5rem 10rem 0 11rem;
    }
    @media (min-width: 1440px) {
       line-height: 50px;
      letter-spacing: -1px;
      font-size: 42px;
      padding: 6rem 0rem 0 0rem;
      margin-bottom: 3rem;
    }
    @media (min-width: 1600px) {
      line-height: 70px;
      font-size: 60px;
      padding: 6rem 0rem 0 0rem;
      margin-bottom: 3rem;
    }
  }
  p {
    font-family: "light", Helvetica, sans-serif;
    @media (min-width: 320px) {
      font-size: 15px;
      padding: 2rem 0 6rem 0rem;
    }
    @media (min-width: 360px) {
      font-size: 15px;
      padding: 2rem 8rem 6rem 0rem;
    }
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0px;
      padding: 2rem 8rem 4rem 3rem;
    }
    @media (min-width: 1024px) {
      line-height: 32px;
      letter-spacing: 0px;
      font-size: 24px;
      padding: 2rem 4rem 0rem 3.5rem;
    }
    @media (min-width: 1280px) {
      font-size: 28px;
      padding: 2rem 8rem 6rem 11rem;
    }
    @media (min-width: 1440px) {
      font-size: 25px;
      letter-spacing: -1.5px;
      line-height: 37px;
      padding: 1rem 8rem 6rem 0rem;
    }
    @media (min-width: 1600px) {
      font-size: 40px;
      letter-spacing: -1.5px;
      line-height: 50px;
      padding: 2rem 8rem 6rem 0rem;
    }
  }
}
