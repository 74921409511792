.project__container {
  margin: 0;
  display: grid;

  height: 800px;

  @media (min-width: 320px) {
    height: 250px;
  }
  @media (min-width: 360px) {
    height: 300px;
  }
  @media (min-width: 400px) {
    height: 350px;
  }
  @media (min-width: 768px) {
    height: 600px;
  }
  @media (min-width: 1024px) {
    height: 750px;
  }
  @media (min-width: 1280px) {
    height: 1000px;
  }
  @media (min-width: 1440px) {
    height: 1200px;
  }

  @media (min-width: 1600px) {
    height: 1450px;
  }
}

// title

.project__title {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h1 {
    text-align: center;
    font-family: "boldest", Helvetica, sans-serif;
    @media (min-width: 320px) {
      font-size: 18px;
    }
    @media (min-width: 375px) {
      font-size: 18px;
    }

    @media (min-width: 768px) {
      font-size: 30px;
    }
    @media (min-width: 1024px) {
      font-size: 35px;
    }
    @media (min-width: 1280px) {
      font-size: 40px;
    }
    @media (min-width: 1440px) {
      font-size: 50px;
    }
    @media (min-width: 1600px) {
      font-size: 55px;
    }
  }
}
//first pic

.project__main-pic-wrapper {
  position: relative;
  padding-top: 56.25%;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
}

//buttons

.project__button-container {
  display: flex;

  justify-content: space-around;

  z-index: 2;
}

//button

.project__view {
  border-radius: 30px;

  color: white;

  border: none;
  font-family: "boldest", Helvetica, sans-serif;
  font-weight: 900;
  @media (min-width: 320px) {
    width: 6rem;
    height: 2rem;
    font-size: 10px;
  }
  @media (min-width: 375px) {
    width: 7rem;
    height: 2.5rem;
    font-size: 10px;
  }
  @media (min-width: 768px) {
    width: 12rem;
    height: 3.5rem;
    font-size: 15px;
  }
  @media (min-width: 1024px) {
    width: 14rem;
    height: 4.5rem;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    width: 17rem;
    height: 5.5rem;
    font-size: 20px;
  }

  @media (min-width: 1600px) {
    width: 25rem;
    height: 7rem;
    font-size: 22px;
  }
}

.project__awrap {
  display: flex;

  height: 50%;
  align-items: center;
}
