.skills__container {
  background: lighten($color: #f3f3f1, $amount: 2);

  width: 100vw;
  display: grid;

  @media (min-width: 320px) {
    padding-top: 3rem;
    height: 70rem;
    grid-template-rows: [skillsTopMarg] 10% [skillsHeader] 40% [skillsPic] 19% [mouse] 31% [bottomMarg] 0%[end];
    grid-template-columns: [skillsLeftMarg] 2% [skillsColPic] 4fr [skillsText] 4fr [skillsMargRight] 2% [end];
  }

  @media (min-width: 768px) {
    height: 100rem;
    padding-top: 3rem;

    grid-template-rows: [skillsTopMarg] 10% [skillsHeader] 40% [skillsPic] 9% [mouse] 41% [bottomMarg] 0%[end];
    grid-template-columns: [skillsLeftMarg] 2% [skillsColPic] 4fr [skillsText] 4fr [skillsMargRight] 2% [end];
  }
  @media (min-width: 1024px) {
    height: 100rem;
    padding-top: 3rem;

    grid-template-rows: [skillsTopMarg] 10% [skillsHeader] 40% [skillsPic] 9% [mouse] 41% [bottomMarg] 0%[end];
    grid-template-columns: [skillsLeftMarg] 2% [skillsColPic] 4fr [skillsText] 4fr [skillsMargRight] 2% [end];
  }

  @media (min-width: 1280px) {
    height: 120rem;
    padding-top: 3rem;

    grid-template-rows: [skillsTopMarg] 10% [skillsHeader] 30% [skillsPic] 19% [mouse] 41% [bottomMarg] 0%[end];
    grid-template-columns: [skillsLeftMarg] 2% [skillsColPic] 4fr [skillsText] 4fr [skillsMargRight] 2% [end];
  }
  @media (min-width: 1600px) {
    height: 150rem;
    padding-top: 3rem;

    grid-template-rows: [skillsTopMarg] 14rem [skillsHeader] 56rem [skillsPic] 22.5rem [mouse] 57.5rem [bottomMarg] 0%[end];
    grid-template-columns: [skillsLeftMarg] 2% [skillsColPic] 4fr [skillsText] 4fr [skillsMargRight] 2% [end];
  }
}
// background

.skills__text-box-container {
  grid-row: skillsTopMarg/skillsPic;
  grid-column: skillsColPic/skillsMargRight;
  // border: green 2px solid;

  // padding-top: 18rem;
  @media (min-width: 320px) {
    padding: 1rem 0rem 0 1.5rem;
  }

  @media (min-width: 768px) {
    padding: 0rem 2rem 0 2rem;
  }
  @media (min-width: 1024px) {
    padding: 0rem 2rem 0 3rem;
  }

  @media (min-width: 1280px) {
    padding: 4rem 0rem 0 11rem;
  }
  @media (min-width: 1600px) {
    padding: 4rem 0rem 0 14rem;
  }

  h1 {
    font-family: "bold", Helvetica, sans-serif;

    @media (min-width: 320px) {
      font-size: 20px;
      padding-right: 2rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 320px) {
      font-size: 23px;
    }

    @media (min-width: 768px) {
      line-height: 35px;
      letter-spacing: -1px;
      font-size: 30px;
      margin-top: 6rem;
      padding-right: 20rem;
    }
    @media (min-width: 1024px) {
      line-height: 40px;
      letter-spacing: -1px;
      font-size: 35px;
      margin-top: 10rem;
      padding-right: 30rem;
    }
    @media (min-width: 1280px) {
      font-size: 40px;
      margin-top: 5rem;
      padding-right: 40rem;
    }

    @media (min-width: 1440px) {
      line-height: 50px;
      letter-spacing: -1px;
      font-size: 42px;
      margin-top: 4rem;
      margin-bottom: 3rem;
    }
    @media (min-width: 1600px) {
      line-height: 65px;
      letter-spacing: -1px;
      font-size: 60px;
      margin-top: 13rem;
    }
  }
  p {
    font-family: "light", Helvetica, sans-serif;
    margin-right: 10rem;

    @media (min-width: 320px) {
      font-size: 13px;
      margin-right: 0;
      margin-left: 0;
      padding-right: 17rem;
    }
    @media (min-width: 360px) {
      font-size: 15px;
      margin-right: 2rem;
      margin-left: 0;
      padding-right: 14rem;
    }
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0px;

      padding-right: 30rem;
    }
    @media (min-width: 1024px) {
      line-height: 32px;
      letter-spacing: 0px;
      font-size: 24px;
      padding-right: 40rem;
    }
    @media (min-width: 1440px) {
      font-size: 25px;
      padding-right: 48rem;
      line-height: 37px;
      letter-spacing: -1.5px;
    }
    @media (min-width: 1600px) {
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -1.5px;
      padding-right: 60rem;
    }
  }
}

.skills__picture-box {
  grid-row: skillsPic/bottomMarg;
  grid-column: skillsColPic/skillsMargRight;

  padding: 4rem 0;
  @media (min-width: 320px) {
    padding: 1rem 0;
  }
}
.skills__picture {
  border-radius: 10px;
  background: url("/images/headshoymybw.jpeg") no-repeat;

  @media (min-width: 320px) {
    height: 80%;
    width: 95%;
    background-size: cover;
    margin: 0 auto;
    background-position: -3rem 0;
  }
  @media (min-width: 360px) {
    background-position: -1rem 0;
  }
  @media (min-width: 400px) {
    background-position: 0rem 0;
  }
  @media (min-width: 768px) {
    height: 100%;
    width: 95%;
    background-size: cover;
    margin: 0 auto;
  }
  @media (min-width: 1280px) {
    height: 100%;
    width: 85%;
    background-size: cover;
    margin: 0 auto;
  }
}

.skills__mouse {
  grid-row: skillsTopMarg/mouse;
  grid-column: skillsText/skillsMargRight;

  background: url("/images/zen.png") no-repeat;
  z-index: 2;
  background-size: contain;
  background-position: 0rem 0rem;
  margin-left: 24rem;
  margin-top: 5rem;
  margin-bottom: 15rem;
  @media (min-width: 320px) {
    grid-row: skillsTopMarg/end;
    grid-column: skillsText/skillsMargRight;
    margin-left: 0.5rem;
    margin-top: 17rem;
    margin-bottom: 0rem;
  }
  @media (min-width: 360px) {
    margin-left: 1rem;
    margin-top: 14rem;
    margin-bottom: 12rem;
  }
  @media (min-width: 400px) {
    margin-left: 3.5rem;
  }
  @media (min-width: 768px) {
    margin-left: 7rem;
  }
  @media (min-width: 1024px) {
    margin-left: 15rem;
    margin-top: 8rem;
  }
  @media (min-width: 1280px) {
    margin-left: 18rem;
    margin-top: 4rem;
    margin-bottom: 20rem;
    margin-right: 8rem;
  }
  @media (min-width: 1440px) {
    margin-left: 23rem;
    margin-top: 4rem;
    margin-bottom: 30rem;
    margin-right: 10rem;
  }
  @media (min-width: 1600px) {
    margin-left: 30rem;
    margin-top: 10rem;
    margin-bottom: 20rem;
    margin-right: 12rem;
  }
}
.skills__emailBox {
  grid-row: skillsPic/bottomMarg;
  grid-column: skillsColPic/skillsMargRight;

  display: flex;

  @media (min-width: 320px) {
    align-items: center;
    margin-bottom: 4rem;
    grid-row: skillsHeader/bottomMarg;

    margin-left: 0.75rem;
  }
  @media (min-width: 360px) {
    margin-bottom: 12rem;
  }
  @media (min-width: 400px) {
    align-items: center;
    margin-bottom: 14rem;
    margin-left: 1.25rem;
  }
  @media (min-width: 768px) {
    align-items: flex-start;
    margin-left: 2rem;
    margin-top: 27rem;
  }
  @media (min-width: 1024px) {
    margin-left: 2.75rem;
    margin-top: 0rem;
    align-items: center;
    margin-bottom: 25rem;
  }
  @media (min-width: 1280px) {
    margin-bottom: 48rem;
    margin-left: 10rem;
  }
  @media (min-width: 1440px) {
    margin-bottom: 48rem;
  }

  @media (min-width: 1600px) {
    margin-top: 10rem;
    margin-left: 14rem;
  }
}
