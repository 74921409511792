.namePage__container {
  background: white;
  height: 100%;
  width: 100%;

  display: grid;

  @media (min-width: 320px) {
    grid-template-rows: [topMarg] 2% [navBox] 12% [rowBody] 11.5fr[belowName] 12fr [belowIcons] 10fr [belowNav] 1.3fr [catch] 0.5rem [bottomMarg] 0rem;
    grid-template-columns: [leftMarg] 3% [toggledNav] 2fr [colBody] 8fr [oppToggledNav] 2fr [rightMarg] 3% [end];
  }

  @media (min-width: 768px) {
    grid-template-rows: [topMarg] 2% [navBox] 12% [rowBody] 11.5fr[belowName] 12fr [belowIcons] 10fr [belowNav] 1.3fr [catch] 0.5rem [bottomMarg] 0rem;
    grid-template-columns: [leftMarg] 2% [toggledNav] 2fr [colBody] 8fr [oppToggledNav] 2fr [rightMarg] 2% [end];
  }

  @media (min-width: 1200px) {
    grid-template-rows: [topMarg] 3% [navBox] 12% [rowBody] 11.5fr[belowName] 12fr [belowIcons] 10fr [belowNav] 1.3fr [catch] 0.5rem [bottomMarg] 0rem;
    grid-template-columns: [leftMarg] 2% [toggledNav] 2fr [colBody] 8fr [oppToggledNav] 2fr [rightMarg] 2% [end];
  }

  @media (min-width: 1440px) {
    grid-template-rows: [topMarg] 4% [navBox] 12% [rowBody] 11.5fr[belowName] 12fr [belowIcons] 10fr [belowNav] 1.3fr [catch] 0.5rem [bottomMarg] 0rem [end];
    grid-template-columns: [leftMarg] 2% [toggledNav] 2fr [colBody] 8fr [oppToggledNav] 2fr [rightMarg] 2% [end];
  }
}

// backgrounds
.namePage__giff {
  grid-row: topMarg/end;
  grid-column: leftMarg/end;
  background: url("https://media.giphy.com/media/Wm9XlKG2xIMiVcH4CP/giphy.gif");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 320px) {
    background-position: 0 0rem;
  }

  @media (min-width: 1024px) {
    background-position: 0 -0rem;
  }

  @media (min-width: 1280px) {
    background-position: 0 -30rem;
  }

  @media (min-width: 1440px) {
    background-position: 0 -30rem;
    background-attachment: fixed;
  }
}
.namepage__overlay {
  grid-row: topMarg/end;
  grid-column: leftMarg/end;
  background: rgba(0, 0, 0, 0.6);
}

//content
.namePage__content-name {
  z-index: 0;
  margin-bottom: 2rem;

  h1 {
    margin: 0;

    font-family: "boldest";
    color: white;

    @media (min-width: 320px) {
      font-size: 40px;
      padding: 0 0rem;
      height: 3rem;
      letter-spacing: -2px;
    }
    @media (min-width: 360px) {
      font-size: 46px;
      height: 4rem;
    }

    @media (min-width: 400px) {
      font-size: 50px;
      height: 4rem;
    }
    @media (min-width: 768px) {
      margin-bottom: 5rem;
      font-size: 95px;
      height: 4rem;
    }

    @media (min-width: 1280px) {
      font-size: 105px;
      height: 5rem;
      letter-spacing: -5px;
    }
    @media (min-width: 1440px) {
      font-size: 120px;
      height: 7rem;
      letter-spacing: -6px;
    }
    @media (min-width: 1600px) {
      letter-spacing: -8px;
      font-size: 150px;
      height: 10rem;
      letter-spacing: -6px;
    }
  }
}
.namePage__content-description {
  p {
    font-family: "light";
    margin: 0;

    color: white;
    z-index: 0;

    @media (min-width: 320px) {
      font-size: 20px;
    }
    @media (min-width: 360px) {
      font-size: 23px;
    }
    @media (min-width: 768px) {
      font-size: 30px;
    }
    @media (min-width: 1440px) {
      font-size: 34px;
      letter-spacing: -1px;
    }
    @media (min-width: 1600px) {
      font-size: 50px;
      letter-spacing: -1px;
    }
  }
}

.namePage__content-wrapper {
  grid-row: rowBody/belowIcons;
  grid-column: leftMarg/end;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 320px) {
    margin-top: 5rem;
  }
  @media (min-width: 360px) {
    margin-top: 0rem;
  }
}

.namePage__icons-box {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 320px) {
    font-size: 7px;
    margin-top: 3rem;

    flex-direction: column-reverse;
  }
  @media (min-width: 768px) {
    font-size: 9px;
    margin-top: 5rem;
  }
  @media (min-width: 1200px) {
    font-size: 9px;
  }

  @media (min-width: 1440px) {
    font-size: 9px;

    margin-top: 6rem;
  }
  @media (min-width: 1600px) {
    font-size: 11px;

    margin-top: 6rem;
  }
}

//icons

.namePage__github {
  color: white;
}
.namePage__scrollTo {
  @media (min-width: 320px) {
    margin-bottom: 1rem;
  }
  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1440px) {
    margin-left: 0rem;
  }

  button {
    span {
      @media (min-width: 320px) {
        margin-left: 0rem;
      }
      @media (min-width: 1024px) {
        margin-left: 3rem;
      }
    }
    border: none;
    height: 4rem;
    width: 18rem;
    border-radius: 22px;

    @media (min-width: 320px) {
      font-size: 20px;
    }
    @media (min-width: 768px) {
      font-size: 20px;
    }

    @media (min-width: 1440px) {
      font-size: 22px;
    }
    @media (min-width: 1600px) {
      font-size: 25px;
    }

    background: none;
    color: white;
    margin-bottom: 15%;
    i {
      @media (min-width: 320px) {
        font-size: 26px;
      }
      @media (min-width: 768px) {
        font-size: 38px;
      }
      @media (min-width: 1500px) {
        font-size: 45px;
      }
    }
  }
}
