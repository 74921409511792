$btn-padding-y-lg: 2rem;
$btn-padding-x-lg: 1.5rem;
$btn-font-size-lg: 20px;
$btn-line-height-lg: 1.5;
$btn-border-radius-lg: 40px;

$btn-padding-y-sm: 1rem;
$btn-padding-x-sm: 2rem;
$btn-font-size-sm: 15px;
$btn-line-height-sm: 1.5;
$btn-border-radius-sm: 40px;

$btn-font-family: "boldest", Helvetica, sans-serif;
$dropdown-min-width: 25rem;
// $dropdown-padding-y:
// $dropdown-spacer:

// $dropdown-link-hover-bg: $gray-100 !default;
$dropdown-font-size: 24px;

// $btn-padding-y-lg: 2rem;
// $btn-padding-x-lg: 2.5rem;
// $btn-font-size-lg: 24px;
// $btn-line-height-lg: 1.5;
// $btn-border-radius-lg: 40px;

// $btn-font-family: "boldest", Helvetica, sans-serif;
// $dropdown-min-width: 25rem;
// // $dropdown-padding-y:
// // $dropdown-spacer:

// // $dropdown-link-hover-bg: $gray-100 !default;

$primary: rgb(44, 33, 131);

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
