.buttons__ac {
  z-index: 4;
  width: 5%;
  height: 2.5rem;
  z-index: 6;
  background: white;
  color: $darkestpurp;
  margin-right: 2%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 320px) {
    width: 10%;
    height: 3rem;
  }
  @media (min-width: 768px) {
    width: 6%;
    height: 3rem;
  }

  &:focus {
    background: white;
  }
}

.buttons__ac--ur {
  color: $thelightestpurp;
}

.buttons__ac--ll {
  color: $lightGarfield;
}

.buttons__ac--lr {
  color: $themaroonish;
}

.buttons {
  width: 10rem;
  height: 70%;
  background: $darkestpurp;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  border: black solid 1px;
  font-weight: 600;

  @media (min-width: 320px) {
    height: 50%;
  }
  @media (min-width: 768px) {
    height: 3.5rem;
  }

  @media (min-width: 1920px) {
    height: 4rem;
    width: 12rem;
    font-size: 18px;
  }
}

.buttons--ul {
  background: $darkestpurp;
}

.buttons--ur {
  background: $thelightestpurp;
}
.buttons--ll {
  background: $lightGarfield;
}
.buttons--lr {
  background: $themaroonish;
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}
