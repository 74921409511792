$darkestpurp: #6b2968;
$lightGarfield: #7f28b6;
$mywhite: #fefefe;
$themaroonish: rgb(152, 41, 118);
$thelightestpurp: #9d28b9;

$pink: #d83f87;
$nearBlack: #2a1b3d;
$navy: #44318d;

$greenGrey: #a4b3b6;
